/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Divider, Tabs } from 'antd';

import * as TYPE from '../store/actions/constants';

import FlatSelector from './FlatSelector';
import FlatInfo from './FlatInfo';
import LoggedInUser from './LoggedInUser';
// import UserList from './UserList';
import SearchUserList from './SearchUserList';
import Section from '../components/Section';

const { TabPane } = Tabs;

const MainScreen = () => {
  const { isAdmin } = useSelector(state => state.auth.userInfo);
  const currentFlat = useSelector(state => state.currentFlat);
  const layout = useSelector(state => state.layout);

  const dispatch = useDispatch();
  const getUsers = key =>
    key === '2' ? dispatch({ type: TYPE.FETCH_USERS }) : null;

  useEffect(() => {
    dispatch({ type: TYPE.FETCH_LAYOUT });
  }, []);

  const usersPane = isAdmin ? (
    <TabPane tab="Пользователи" key="2">
      <SearchUserList />
    </TabPane>
  ) : null;

  const sections = layout.map((e, i) => (
    <TabPane tab={`Подъезд ${i + 1}`} key={i + 3}>
      <Section sectionData={e} />
    </TabPane>
  ));

  return (
    <div
      style={{
        margin: '20px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // height: '100vh',
      }}
    >
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={<LoggedInUser />}
        onTabClick={getUsers}
      >
        <TabPane tab="Поиск квартиры" key="1">
          <FlatSelector />
          <Divider />
          <FlatInfo flatData={currentFlat} />
        </TabPane>
        {usersPane}
        {sections}
      </Tabs>
    </div>
  );
};

export default MainScreen;
