/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Descriptions, List } from 'antd';
import TenantsList from './TenantsList';

const { Item } = Descriptions;

const FlatInfo = ({ flatData } = {}) => {
  const {
    flat,
    owners,
    section,
    floor,
    area,
    rooms,
    tenants,
  } = flatData;
  const { isAdmin } = useSelector(state => state.auth.userInfo);

  const ownersBlock = isAdmin ? (
    <Item label="Владельцы" span={2}>
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={owners}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
    </Item>
  ) : null;
  return (
    <Descriptions
      title=" Информация о квартире"
      column={2}
      size="small"
      bordered
    >
      <Item label="Квартира" span={2}>
        {flat}
      </Item>
      <Item label="Секция">{section}</Item>
      <Item label="Этаж">{floor}</Item>
      <Item label="Комнат">{rooms}</Item>
      <Item label="Площадь">{area}</Item>
      {ownersBlock}
      <Item label="Жильцы" span={2}>
        <TenantsList tenants={tenants} />
      </Item>
    </Descriptions>
  );
};

export default FlatInfo;
