/* eslint-disable react/prop-types */
import React from 'react';
import { Alert } from 'antd';

const AuthStatus = ({ status }) => {
  if (status === 403) {
    return (
      <Alert
        style={{ margin: '20px 0 0 0' }}
        message="Ошибка авторизации"
        description="Похоже, Вы не явлетесь жильцом нашего дома. Если это не так, обратитесь к админам"
        type="error"
        showIcon
      />
    );
  }
  return '';
};

export default AuthStatus;
