import React from 'react';
import { useDispatch } from 'react-redux';
// import TelegramLoginButton from 'react-telegram-login';
import { LoginButton, LoginButtonSize } from 'react-telegram-auth';
import { Card } from 'antd';

import * as TYPE from '../store/actions/constants';

const TelegramAuth = () => {
  const dispatch = useDispatch();
  const auth = (user) =>
    dispatch({
      type: TYPE.LOGIN,
      user,
    });
  return (
    <Card title="Необходимо войти с помощью Telegram">
      {/* <TelegramLoginButton
        dataOnauth={auth}
        botName={process.env.BOT_NAME}
        buttonSize="medium"
      /> */}
      <LoginButton
        botName={process.env.BOT_NAME}
        buttonSize={LoginButtonSize.Medium}
        lang="en"
        usePic={false}
        cornerRadius={20}
        onAuthCallback={auth}
        requestAccess="write"
      />
    </Card>
  );
};

export default TelegramAuth;
