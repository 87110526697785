import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spin, Descriptions, Checkbox } from 'antd';

import * as TYPE from '../store/actions/constants';

import UserFlatsSelector from './UserFlatsSelector';

const UserInfo = () => {
  const userLoading = useSelector(state => state.userLoading);
  const userModal = useSelector(state => state.userModal);
  const currentUser = useSelector(state => state.currentUser);
  const {
    flats,
    id,
    firstName,
    lastName,
    username,
    newFlats,
    isActive,
    isAdmin,
  } = currentUser;

  const userFlats = flats || [];

  const [newActive, setNewActive] = useState();
  const [newAdmin, setNewAdmin] = useState();
  useEffect(() => {
    setNewActive(isActive);
    setNewAdmin(isAdmin);
  }, [userLoading]);

  const dispatch = useDispatch();
  const submit = () =>
    dispatch({
      type: TYPE.SUBMIT_USERINFO,
      id,
      flats: newFlats || flats,
      isActive: newActive,
      isAdmin,
    });
  const cancel = () => dispatch({ type: TYPE.RESET_USERINFO });

  return (
    <Modal
      title="Информация о пользователе"
      visible={userModal}
      onOk={submit}
      onCancel={cancel}
    >
      {userLoading ? (
        <Spin />
      ) : (
        <div>
          <Descriptions column={2} size="small">
            <Descriptions.Item label="id">{id}</Descriptions.Item>
            <Descriptions.Item label="Ник">
              {username}
            </Descriptions.Item>
            <Descriptions.Item label="Имя">
              {firstName}
            </Descriptions.Item>
            <Descriptions.Item label="Фамилия">
              {lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Активен">
              <Checkbox
                checked={newActive}
                onChange={e => setNewActive(e.target.checked)}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Администратор">
              <Checkbox
                disabled
                checked={newAdmin}
                onChange={e => setNewAdmin(e.target.checked)}
              />
            </Descriptions.Item>
          </Descriptions>
          <UserFlatsSelector userFlats={userFlats} />
        </div>
      )}
    </Modal>
  );
};

export default UserInfo;
