/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
// import uuid from 'uuid/v4';

import Flat from './Flat';

const Floor = ({ floorNumber = 0, floorData = [] }) =>  (
  <div style={{ display: 'flex' }}>
    <div
      style={{
        width: '30px',
        alignSelf: 'flex-end',
        fontSize: '18px',
        fontWeight: 'bold',
      }}
    >
      {floorNumber}
    </div>
    {floorData.map((e, i) => (<Flat flatData={e} key={i} />))}
  </div>
  );

export default Floor;
