/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import FlatPopover from '../containers/FlatPopover';

const Flat = ({ flatData = {} }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const handleVisibleChange = visible => {
    setPopoverVisible(visible);
  };
  return (
    <Popover
      content={
        <FlatPopover
          flatNumber={flatData.flat}
          visible={popoverVisible}
        />
      }
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={handleVisibleChange}
    >
      <Button
        style={{
          width: '30px',
          padding: 0,
          margin: '5px',
          textAlign: 'center',
        }}
        type={flatData.tenants ? 'primary' : 'default'}
      >
        {flatData.flat}
      </Button>
    </Popover>
  );
  // return <div>{JSON.stringify(flatData)}</div>;
};

export default Flat;
