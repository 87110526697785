/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';

import * as TYPE from '../store/actions/constants';

const flats = Array.from(Array(629).keys()).map(e => e + 1);

const { Option } = Select;

const UserFlatsSelector = () => {
  const userFlats = useSelector(state => state.currentUser.flats);
  const newFlats = useSelector(state => state.currentUser.newFlats);

  const dispatch = useDispatch();
  const setFlats = selectedFlats =>
    dispatch({
      type: TYPE.SET_USER_FLATS,
      data: selectedFlats,
    });

  const options = flats.map(e => (
    <Option key={e} value={e}>
      {e}
    </Option>
  ));
  return (
    <div style={{ padding: '0 0 20px 0' }}>
      {'Квартиры: '}
      <Select
        style={{ width: '400px' }}
        name="flat"
        mode="multiple"
        showSearch
        optionFilterProp="children"
        filterOption
        value={newFlats || userFlats}
        onChange={e => setFlats(e)}
      >
        {options}
      </Select>
    </div>
  );
};

export default UserFlatsSelector;
