/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Table, Input, Button, Icon } from 'antd';
import Highlighter from 'react-highlight-words';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as TYPE from '../store/actions/constants';
import UserInfo from './UserInfo';

const SearchUserList = () => {
  const [searchText, setSearchText] = useState('');
  const users = useSelector(state => state.users);

  const dispatch = useDispatch();
  const showUser = id => dispatch({ type: TYPE.FETCH_USERINFO, id });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          // this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      title: 'Ник',
      dataIndex: 'username',
      key: 'username',
      width: '33%',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '33%',
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '33%',
      ...getColumnSearchProps('lastName'),
    },
  ];
  return (
    <div>
      <UserInfo />
      <Table
        scroll={{ y: 400 }}
        // style={{ width: '500px' }}
        columns={columns}
        dataSource={users}
        rowKey="id"
        pagination={false}
        size="small"
        onRow={({ id }) => ({
          onClick: () => {
            showUser(id);
          }, // double click row
        })}
      />
    </div>
  );
};

export default SearchUserList;
