import React from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';

import * as TYPE from '../store/actions/constants';

const flats = Array.from(Array(629).keys()).map(e => e + 1);

const { Option } = Select;

const FlatSelector = () => {
  const dispatch = useDispatch();
  const getFlat = flat =>
    dispatch({
      type: TYPE.FETCH_FLATINFO,
      flat,
    });

  const options = flats.map(e => (
    <Option key={e} value={e}>
      {e}
    </Option>
  ));
  return (
    <div style={{ padding: '0 0 20px 0' }}>
      <span
        style={{
          display: 'inline-block',
          width: '15%',
          float: 'left',
          padding: '7px 14px',
        }}
      >
        Квартира
      </span>
      <Select
        name="flat"
        showSearch
        placeholder="Выберите квартиру..."
        optionFilterProp="children"
        filterOption
        onSelect={getFlat}
        style={{
          display: 'inline-block',
          width: '85%',
          float: 'left',
        }}
      >
        {options}
      </Select>
    </div>
  );
};

export default FlatSelector;
