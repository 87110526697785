/* eslint-disable react/prop-types */
import React from 'react';
import { Table } from 'antd';

const { Column } = Table;
// const IconFont = Icon.createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_1320406_yejy6cob6x.js',
// });

const TenantsList = ({ tenants }) => (
  <Table dataSource={tenants} rowKey="id" pagination={false}>
    {/* <Column title="id" dataIndex="id" key="id" /> */}
    <Column title="Ник" dataIndex="username" key="username" />
    <Column title="Имя" dataIndex="firstName" key="firstName" />
    <Column title="Фамилия" dataIndex="lastName" key="lastName" />
    {/* <Column
      title={
        <Tooltip title="Пользователь включен" placement="left">
          <IconFont type="icon-enabled" />
        </Tooltip>
      }
      dataIndex="isActive"
      key="isActive"
      width={30}
      render={t => <Checkbox checked={t} />}
    />
    <Column
      title={
        <Tooltip
          title="Пользователь является админом"
          placement="left"
        >
          <IconFont type="icon-admin" />
        </Tooltip>
      }
      dataIndex="isAdmin"
      key="isAdmin"
      width={30}
      render={t => <Checkbox checked={t} />}
    /> */}
  </Table>
);
export default TenantsList;
