import * as TYPE from '../actions/constants';
// import sampleState from './sampleState';

const reducer = (
  state = {
    auth: {},
    currentFlat: {},
    flatLoading: false,
    users: [],
    usersLoading: false,
    currentUser: {},
    userLoading: false,
    userModal: false,
    layout: [],
    layoutLoading: false,
  },
  action
) => {
  switch (action.type) {
    case TYPE.SET_AUTH:
      return { ...state, auth: action.data };
    case TYPE.FETCH_FLATINFO:
      return { ...state, flatLoading: true };
    case TYPE.SET_FLATINFO:
      return {
        ...state,
        flatLoading: false,
        currentFlat: action.data,
      };
    case TYPE.FETCH_USERS:
      return { ...state, usersLoading: true };
    case TYPE.SET_USERS:
      return { ...state, usersLoading: false, users: action.data };
    case TYPE.FETCH_USERINFO:
      return { ...state, userLoading: true, userModal: true };
    case TYPE.SET_USERINFO:
      return {
        ...state,
        userLoading: false,
        currentUser: action.data,
      };
    case TYPE.SUBMIT_USERINFO:
      return { ...state, userModal: false, currentUser: {} };
    case TYPE.RESET_USERINFO:
      return { ...state, userModal: false, currentUser: {} };
    case TYPE.SET_USER_FLATS:
      return {
        ...state,
        currentUser: { ...state.currentUser, newFlats: action.data },
      };
    case TYPE.FETCH_LAYOUT:
      return { ...state, layoutLoading: true };
    case TYPE.SET_LAYOUT:
      return { ...state, layoutLoading: false, layout: action.data };

    default:
      return state;
  }
};
export default reducer;
