import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';

const LoggedInUser = () => {
  const { firstName, lastName, avatar } = useSelector(
    state => state.auth.userInfo
  );

  return (
    <div>
      <Avatar
        src={avatar}
        alt={`${firstName} ${lastName} `}
        shape="square"
      />
      <span>{` ${firstName} ${lastName}`}</span>
    </div>
  );
};

export default LoggedInUser;
