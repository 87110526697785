export const FETCH_DATA = 'FETCH_DATA';
export const SET_DATA = 'SET_DATA';
export const FETCH_FLATINFO = 'FETCH_FLATINFO';
export const SET_FLATINFO = 'SET_FLATINFO';
export const SET_AUTH = 'SET_AUTH';
export const LOGIN = 'LOGIN';
export const FETCH_USERS = 'FETCH_USERS';
export const SET_USERS = 'SET_USERS';
export const FETCH_USERINFO = 'FETCH_USERINFO';
export const SET_USERINFO = 'SET_USERINFO';
export const SUBMIT_USERINFO = 'SUBMIT_USERINFO';
export const RESET_USERINFO = 'RESET_USERINFO';
export const SET_USER_FLATS = 'SET_USER_FLATS';
export const FETCH_LAYOUT = 'FETCH_LAYOUT';
export const SET_LAYOUT = 'SET_LAYOUT';
