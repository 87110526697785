import {
  put,
  takeLatest,
  all,
  select,
  // delay, call,
} from 'redux-saga/effects';
import { get, post, patch } from 'axios';

import * as TYPE from '../actions/constants';

const apiUrl = process.env.API_URL;

function* fetchFlatInfo({ flat }) {
  try {
    const token = yield select(({ auth = {} }) => auth.token);
    const { data } = yield get(`${apiUrl}/flats/${flat}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Max-Age': 600,
      },
    });
    yield put({ type: TYPE.SET_FLATINFO, data });
  } catch (e) {
    console.log(e);
  }
}

function* fetchLayout() {
  try {
    const token = yield select(({ auth = {} }) => auth.token);
    const { data } = yield get(`${apiUrl}/flats`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Max-Age': 600,
      },
    });
    yield put({ type: TYPE.SET_LAYOUT, data });
  } catch (e) {
    console.log(e);
  }
}

function* fetchUserInfo({ id }) {
  try {
    const token = yield select(({ auth = {} }) => auth.token);
    const { data } = yield get(`${apiUrl}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Max-Age': 600,
      },
    });
    yield put({ type: TYPE.SET_USERINFO, data });
  } catch (e) {
    console.log(e);
  }
}

function* fetchUsers() {
  try {
    const token = yield select(({ auth = {} }) => auth.token);
    const { data } = yield get(`${apiUrl}/users?sort=desc`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Max-Age': 600,
      },
    });
  console.log('object')

    yield put({ type: TYPE.SET_USERS, data });
  console.log('object')

  } catch (e) {
    console.log(e);
  }
}

function* login({ user }) {
  try {
    const { data, status } = yield post(`${apiUrl}/login`, user);
    yield put({ type: TYPE.SET_AUTH, data: { ...data, status } });
  } catch ({ response: { status } }) {
    yield put({ type: TYPE.SET_AUTH, data: { status } });
  }
}

function* setNewFlats({ id, flats, isActive, isAdmin }) {
  try {
    const token = yield select(({ auth = {} }) => auth.token);
    yield patch(
      `${apiUrl}/users/${id}`,
      { flats, isActive, isAdmin },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Max-Age': 600,
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
}

// function* periodicUpdate() {
//   while (true) {
//     yield call(fetchData);
//     yield delay(20000);
//   }
// }

function* actionWatcher() {
  yield takeLatest(TYPE.FETCH_FLATINFO, fetchFlatInfo);
  yield takeLatest(TYPE.FETCH_USERINFO, fetchUserInfo);
  yield takeLatest(TYPE.FETCH_USERS, fetchUsers);
  yield takeLatest(TYPE.LOGIN, login);
  yield takeLatest(TYPE.SUBMIT_USERINFO, setNewFlats);
  yield takeLatest(TYPE.FETCH_LAYOUT, fetchLayout);

}
export default function* rootSaga() {
  yield all([
    actionWatcher(),
    // periodicUpdate(),
  ]);
}
