/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import * as TYPE from '../store/actions/constants';
import FlatInfo from './FlatInfo';

const FlatPopover = ({ flatNumber, visible } = {}) => {
  const dispatch = useDispatch();
  const getFlat = flat =>
    dispatch({
      type: TYPE.FETCH_FLATINFO,
      flat,
    });

  useEffect(() => {
    if (visible) getFlat(flatNumber);
  }, []);
  const currentFlat = useSelector(state => state.currentFlat);
  const flatLoading = useSelector(state => state.flatLoading);

  return flatLoading ? (
    <Spin />
  ) : (
    <div style={{ width: '400px' }}>
      <FlatInfo flatData={currentFlat} />
    </div>
  );
};

export default FlatPopover;
