/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { Divider } from 'antd';
import Floor from './Floor';

const Section = ({ sectionData = [] }) => (
  <div>
    {sectionData.map((e, i) => (
      <div key={sectionData.length - i}>
        <Divider style={{ margin: 0 }} />
        {}
        <Floor floorNumber={sectionData.length - i} floorData={e} />
      </div>
    ))}
  </div>
  );

export default Section;
