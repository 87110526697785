import React from 'react';
import { useSelector } from 'react-redux';

import TelegramAuth from './TelegramAuth';
import AuthStatus from './AuthStatus';
import MainScreen from './MainScreen';

const App = () => {
  const authStatus = useSelector(state => state.auth.status);
  const screen =
    authStatus === 200 ? (
      <MainScreen />
    ) : (
      <div>
        <TelegramAuth />
        <AuthStatus status={authStatus} />
      </div>
    );
  return (
    <div
      style={{
        margin: '20px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // height: '100vh',
      }}
    >
      {screen}
    </div>
  );
};

export default App;
