/* eslint-disable no-underscore-dangle */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './css/antd-theme.less';

import initStore from './store';
import App from './containers/App';

render(
  React.createElement(
    Provider,
    { store: initStore() },
    React.createElement(App)
  ),
  document.getElementById('root')
);
